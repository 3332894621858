@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~react-image-gallery/styles/css/image-gallery.css";

.image-gallery {
  width: 100%;
}

/* className="object-cover w-full h-full" */
.image-gallery-slide img {
  width: 100%;
  height: auto;
  height: 500px;
  overflow: hidden;
  object-fit: fill;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  max-height: 80vh;
  height: 80vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
